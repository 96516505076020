class ToggleHeaderNav {
  constructor(el) {
    this._toggle(el);
  }

  _toggle(el) {
    const headerNav = $('#header-nav');
    let isHeaderNavOpen = false;

    $(el).on('click', function() {
      headerNav.slideToggle();
      $('body').toggleClass('is-sidebar-active');
      isHeaderNavOpen = !isHeaderNavOpen;

      return false;
    });

    $(document).click(function(){
      headerNav.hide();
      $('body').removeClass('is-sidebar-active');
      isHeaderNavOpen = false;
    });

    /* Clicks within the dropdown won't make
       it past the dropdown itself */
    headerNav.click(function(e){
      e.stopPropagation();
    });
  }
}

export default ToggleHeaderNav;
